import style from '../styles/About.module.css';

const About = () => {
        
        return (
                <p className={style.about}>
                        about me <br></br>
                        » krzywy chuj <br></br>
                        » country of living liban <br></br>
                        » b <br></br>
                        » kaknan <br></br>
                        » rogue lineage enjoyer <br></br>
                </p>
        )
}

export default About;
